exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legacy-x-js": () => import("./../../../src/pages/legacy-x.js" /* webpackChunkName: "component---src-pages-legacy-x-js" */),
  "component---src-pages-micro-js": () => import("./../../../src/pages/micro.js" /* webpackChunkName: "component---src-pages-micro-js" */),
  "component---src-pages-not-forgotten-js": () => import("./../../../src/pages/not-forgotten.js" /* webpackChunkName: "component---src-pages-not-forgotten-js" */),
  "component---src-pages-submit-js": () => import("./../../../src/pages/submit.js" /* webpackChunkName: "component---src-pages-submit-js" */),
  "component---src-pages-submit-test-js": () => import("./../../../src/pages/submit-test.js" /* webpackChunkName: "component---src-pages-submit-test-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-quilt-block-js": () => import("./../../../src/templates/quiltBlock.js" /* webpackChunkName: "component---src-templates-quilt-block-js" */)
}

